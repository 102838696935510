import Footer from "./components/footer";
import Header from "./components/header";
import Contact from "./sections/contact";
import Mission from "./sections/mission";
import Other from "./sections/other";
import OurServices from "./sections/ourServices";
import SliderSection from "./sections/slider";
import Vision from "./sections/vision";

function App() {
  return (
    <div className="">
      <Header />
      <main className="overflow-hidden">
        <SliderSection />
        <Other />
        <OurServices />
        <Vision />
        <Mission />
        <Contact />
      </main>
      <Footer />
    </div>
  );
}

export default App;
