import ScrollAnimation from "react-animate-on-scroll";
import Logo from "../components/logo";

const Mission = () => {
    return (
        <section id="mission">
            <div className="py-16">
                <div className="mx-auto px-6 max-w-6xl text-gray-500">
                    <div className="text-center">
                        <h2 className="text-3xl text-gray-950 dark:text-white font-semibold">Misyon</h2>
                    </div>
                    <div className="grid gap-12 md:gap-2 md:grid-cols-2 lg:grid-cols-5 items-center lg:gap-24 mt-10">
                        <div className="lg:col-span-2 hidden md:block">
                            <ScrollAnimation animateIn="animate__fadeInLeft">
                                <div className="md:pr-6 lg:pr-0 text-center">
                                    <div className="flex gap-5 items-center justify-center">
                                        <Logo className={"h-auto md:max-h-52 max-h-24 md:mx-auto md:mb-10"} />
                                        <ScrollAnimation animateIn="animate__fadeInRight" className="md:hidden">
                                            <div className="overflow-hidden border bg-white dark:bg-[--card-dark-bg] rounded-[--card-border-radius] border-[--ui-light-border-color] dark:border-[--ui-dark-border-color] lg:max-w-[500px] md:max-w-[200px] max-w-[150px]">
                                                <img src="./mission.png" alt="fyz gayrimenkul misyon" />
                                            </div>
                                        </ScrollAnimation>
                                    </div>
                                    <p className="mt-6 text-gray-500 dark:text-gray-300">Gayrimenkul sektöründe, müşterilerimizin beklenti ve ihtiyaçlarına uygun çözümler üretip pazarlama, satış, kiralama ve sonrasında koşulsuz müşteri memnuniyeti bilinciyle, hızlı ve güvenilir hizmetler sunmak amacındayız.</p>
                                </div>
                            </ScrollAnimation>
                        </div>

                        {/* mobile */}

                        <div className="lg:col-span-2 md:hidden">
                            <div className="md:pr-6 lg:pr-0 text-center">
                                <div className="flex gap-5 items-center justify-center">
                                    <ScrollAnimation animateIn="animate__fadeInLeft">
                                        <Logo className={"h-auto md:max-h-52 max-h-24 md:mx-auto md:mb-10"} />
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__fadeInRight">
                                        <div className="overflow-hidden border bg-white dark:bg-[--card-dark-bg] rounded-[--card-border-radius] border-[--ui-light-border-color] dark:border-[--ui-dark-border-color] md:max-w-[500px] max-w-[150px] mx-auto">
                                            <img src="./mission.png" alt="fyz gayrimenkul misyon" />
                                        </div>
                                    </ScrollAnimation>
                                </div>
                                <ScrollAnimation animateIn="animate__fadeInUp">
                                    <p className="mt-6 text-gray-500 dark:text-gray-300">Gayrimenkul sektöründe, müşterilerimizin beklenti ve ihtiyaçlarına uygun çözümler üretip pazarlama, satış, kiralama ve sonrasında koşulsuz müşteri memnuniyeti bilinciyle, hızlı ve güvenilir hizmetler sunmak amacındayız.</p>
                                </ScrollAnimation>
                            </div>
                        </div>

                        <div className="lg:col-span-3 md:block hidden">
                            <ScrollAnimation animateIn="animate__fadeInRight">
                                <div className="overflow-hidden border bg-white dark:bg-[--card-dark-bg] rounded-[--card-border-radius] border-[--ui-light-border-color] dark:border-[--ui-dark-border-color] md:max-w-[500px] max-w-[150px] mx-auto">
                                    <img src="./mission.png" alt="fyz gayrimenkul misyon" />
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Mission;