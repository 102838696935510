import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-cards'
import PhoneIcon from '../icons/phoneIcon';
import FindIcon from '../icons/findIcon';
import LayerIcon from '../icons/layerIcon';
import InternationalIcon from '../icons/internationalIcon';

/* eslint-disable jsx-a11y/anchor-is-valid */

const SliderSection = () => {
    return (
        <section id='slider' className="relative">
            <div className="relative pt-24 lg:pt-28">
                <div className="mx-auto px-6 max-w-7xl md:px-12">
                    <div className="text-center sm:mx-auto sm:w-10/12 lg:mr-auto lg:mt-0 lg:w-4/5 animate__animated animate__fadeInUp">
                        <h1 className="mt-8 text-wrap text-4xl md:text-5xl font-semibold text-gray-950 dark:text-white xl:text-5xl xl:[line-height:1.125]"><span className='!text-[#ff4402]'>FYZ</span><span>GAYRİMENKUL</span> <br className="hidden sm:block" /> ile öne çıkmaya hazır olun</h1>
                        <p className="text-wrap mx-auto mt-8 max-w-2xl text-lg text-gray-700 dark:text-gray-300 hidden sm:block">4 yıllık deneyimimiz, yüksek nitelikli hizmet anlayışımız ve gayrimenkul danışmanlığındaki ayrıcalıklı becerimizle size özel seçenekler üretir, idealinizdekini buluruz.</p>
                        <p className="text-wrap mx-auto mt-6 max-w-2xl text-gray-700 dark:text-gray-300 sm:hidden">Highly customizable components for building modern websites and applications, with your personal spark.</p>
                        <div className="mt-8 flex flex-col items-center justify-center gap-4">
                            <div className="p-1 rounded-[calc(var(--btn-border-radius)+4px)] bg-gray-950/5 border dark:border-white/10 dark:bg-white/5">
                                <a
                                    href="tel:+90 554 657 55 33"
                                    className="*:select-none cursor-pointer dark:shadow-primary-500/10 *:disabled:opacity-20 disabled:*:text-gray-300 disabled:dark:*:text-gray-700 dark:*:disabled:!text-white group relative z-[1] flex h-11 items-center justify-center gap-1.5 rounded-[--btn-border-radius] border border-primary-600 bg-primary-500 px-4 text-base text-white shadow-md shadow-primary-200 before:absolute before:inset-0 before:rounded-[calc(var(--btn-border-radius)-1px)] before:border before:border-primary-500 before:bg-gradient-to-b before:from-primary-600 hover:bg-primary-600 active:bg-primary-700 disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-950/40 disabled:before:border-transparent disabled:before:bg-gray-100 disabled:before:from-transparent dark:border-0 dark:bg-primary-600 dark:before:border-0 dark:before:border-t dark:before:border-primary-400 dark:before:shadow-inner dark:before:shadow-white/10 dark:hover:bg-primary-700 dark:active:bg-primary-800 dark:active:before:from-primary-700 dark:disabled:border dark:disabled:border-gray-800/50 disabled:dark:bg-gray-900 dark:disabled:before:bg-gray-900 dark:disabled:before:from-gray-900 dark:disabled:before:shadow-none [&>*:not(.sr-only)]:relative"
                                >
                                    <span className='text-gray-950 dark:text-white'><PhoneIcon /></span>
                                    <span className="text-nowrap text-gray-950 dark:text-white">Bizi Arayın</span></a
                                >
                            </div>
                        </div>
                    </div>
                    <div className="-mx-6 relative mt-8 sm:mt-12 max-w-xl sm:mx-auto">
                        <span className='text-lg text-gray-950 dark:text-white text-center mb-3 mx-auto block animate__animated animate__pulse'>Bizimle birlikte yol almak için birkaç neden...</span>
                        <div className="absolute inset-0 -top-8 left-1/2 -z-20 h-56 w-full -translate-x-1/2 dark:opacity-10 [background-image:linear-gradient(to_bottom,transparent_98%,theme(colors.gray.200/75%)_98%),linear-gradient(to_right,transparent_94%,_theme(colors.gray.200/75%)_94%)] [background-size:16px_35px] [mask:radial-gradient(black,transparent_95%)]"></div>
                        <div className="absolute top-12 inset-x-0 w-2/3 h-1/3 -z-[1] rounded-full bg-primary-300 dark:bg-white mx-auto blur-3xl"></div>
                        
                        <Swiper
                            modules={[Pagination]}
                            effect='cube'
                            cubeEffect={{
                                slideShadows: false,
                                shadow: false,
                                shadowOffset: 20,
                                shadowScale: 0.94,
                            }}
                            loop
                            centeredSlides
                            grabCursor
                            autoplay={{
                                delay: 3000,
                                duration : 500
                            }}
                            slidesPerView={1}
                            >
                            <SwiperSlide>
                            <div className="px-6 pt-2 pb-12">
                                    <div className="bg-white shadow-xl shadow-gray-950/5 p-[2rem] rounded-[--card-border-radius] border-[--ui-light-border-color] dark:border-[--ui-dark-border-color] dark:bg-[--card-dark-bg] relative border-[length:var(--border-width)] [--anchor:100] [--border-radius:calc(var(--radius)*1px)] [--border-width:calc(var(--border)*1px)] [--border:1] [--glow:60] [--hue:179] [--lightness:55%] dark:[--lightness:14%] [--opacity:1] [--radius:24] [--saturation:78%] dark:[--saturation:97%] [--speed:2]">
                                        <div className='text-gray-950 dark:text-red-200'>
                                            <div className='flex justify-center'>
                                                <FindIcon />
                                            </div>
                                            <p className="mt-6 text-lg text-gray-950 dark:text-white text-center">Siz ararsınız, biz buluruz</p>
                                        </div>
                                        <span className="glow absolute inset-[calc(var(--border-width)*-1)] rounded-[--card-border-radius] border-[length:var(--border-width)] border-transparent ![mask-clip:padding-box,_border-box] ![mask-composite:intersect] [mask:linear-gradient(transparent,transparent),linear-gradient(white,white)]">
                                            <span className="absolute inline-block aspect-square h-20 bg-[radial-gradient(circle_at_right,hsl(0_0%_100%/0),transparent_50%),radial-gradient(circle_at_right,hsl(var(--hue)_var(--saturation)_var(--lightness,50%)/1)_50%,transparent)] dark:bg-[radial-gradient(circle_at_right,hsl(0_0%_100%/0.75),transparent_50%),radial-gradient(circle_at_right,hsl(var(--hue)_var(--saturation)_var(--lightness,50%)/1)_50%,transparent)] opacity-[var(--opacity)] [animation:loop_5s_infinite_linear] [offset-anchor:calc(var(--anchor)*1%)_50%] [offset-path:rect(0_100%_100%_0_round_calc(var(--glow)*1px))]"></span>
                                        </span>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                            <div className="px-6 pt-2 pb-12">
                                    <div className="bg-white shadow-xl shadow-gray-950/5 p-[2rem] rounded-[--card-border-radius] border-[--ui-light-border-color] dark:border-[--ui-dark-border-color] dark:bg-[--card-dark-bg] relative border-[length:var(--border-width)] [--anchor:100] [--border-radius:calc(var(--radius)*1px)] [--border-width:calc(var(--border)*1px)] [--border:1] [--glow:60] [--hue:295] [--lightness:63%] [--opacity:1] [--radius:24] [--saturation:100%] [--speed:2]">
                                        <div className="text-gray-950 dark:text-red-200">
                                        <div className='flex justify-center'>
                                            <LayerIcon />
                                        </div>
                                            <p className="mt-6 text-lg text-gray-950 dark:text-white text-center">Belge ve raporlarla çalışırız</p>
                                        </div>
                                        <span className="glow absolute inset-[calc(var(--border-width)*-1)] rounded-[--card-border-radius] border-[length:var(--border-width)] border-transparent ![mask-clip:padding-box,_border-box] ![mask-composite:intersect] [mask:linear-gradient(transparent,transparent),linear-gradient(white,white)]">
                                            <span className="absolute inline-block aspect-square h-20 bg-[radial-gradient(circle_at_right,hsl(0_0%_100%/0),transparent_50%),radial-gradient(circle_at_right,hsl(var(--hue)_var(--saturation)_var(--lightness,50%)/1)_50%,transparent)] dark:bg-[radial-gradient(circle_at_right,hsl(0_0%_100%/0.75),transparent_50%),radial-gradient(circle_at_right,hsl(var(--hue)_var(--saturation)_var(--lightness,50%)/1)_50%,transparent)] opacity-[var(--opacity)] [animation:loop_5s_infinite_linear] [offset-anchor:calc(var(--anchor)*1%)_50%] [offset-path:rect(0_100%_100%_0_round_calc(var(--glow)*1px))]"></span>
                                        </span>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                            <div className="px-6 pt-2 pb-12">
                                    <div className="bg-white shadow-xl shadow-accent-950/5 p-[2rem] rounded-[--card-border-radius] border-[--ui-light-border-color] dark:border-[--ui-dark-border-color] dark:bg-[--card-dark-bg] relative border-[length:var(--border-width)] [--anchor:100] [--border-radius:calc(var(--radius)*1px)] [--border-width:calc(var(--border)*1px)] [--border:1] [--glow:60] [--hue:240] [--lightness:67%] [--opacity:1] [--radius:24] [--saturation:84%] [--speed:2]">
                                        <div>
                                            <div className="h-12 w-fit mx-auto text-gray-950 dark:text-red-200">
                                                <InternationalIcon />
                                            </div>
                                            <p className="mt-6 text-lg text-gray-950 dark:text-white text-center">Uluslararası bakışa sahiptir</p>
                                        </div>
                                        <span className="glow absolute inset-[calc(var(--border-width)*-1)] rounded-[--card-border-radius] border-[length:var(--border-width)] border-transparent ![mask-clip:padding-box,_border-box] ![mask-composite:intersect] [mask:linear-gradient(transparent,transparent),linear-gradient(white,white)]">
                                            <span className="absolute inline-block aspect-square h-20 bg-[radial-gradient(circle_at_right,hsl(0_0%_100%/0),transparent_50%),radial-gradient(circle_at_right,hsl(var(--hue)_var(--saturation)_var(--lightness,50%)/1)_50%,transparent)] dark:bg-[radial-gradient(circle_at_right,hsl(0_0%_100%/0.75),transparent_50%),radial-gradient(circle_at_right,hsl(var(--hue)_var(--saturation)_var(--lightness,50%)/1)_50%,transparent)] opacity-[var(--opacity)] [animation:loop_5s_infinite_linear] [offset-anchor:calc(var(--anchor)*1%)_50%] [offset-path:rect(0_100%_100%_0_round_calc(var(--glow)*1px))]"></span>
                                        </span>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SliderSection;