import InstagramIcon from "../icons/instagramIcon";
import Logo from "./logo";

const Footer = () => {
    return (
        <footer className="">
            <div className="max-w-6xl mx-auto px-6 py-12 flex flex-col gap-y-5 text-gray-600 2xl:px-0">
                <div className="flex gap-x-3 items-center justify-center">
                    <Logo className={"h-16"} />
                    <span className="text-gray-600 dark:text-gray-300">
                        <span className="text-[#ff4402] dark:text-[#ff4402]">fyz</span>
                        gayrimenkul
                    </span>
                    <span className="block text-sm text-center text-gray-500 dark:text-gray-400">© 2024 Tüm hakları saklıdır.</span>
                </div>
                <ul className="flex justify-center space-x-5">
                    <li className="w-5 h-5 rounded-full overflow-hidden cursor-pointer">
                        <img src="./sahibinden_icon.png" alt="sahibinden logo" />
                    </li>
                    <li className="w-5 h-5 rounded-full overflow-hidden cursor-pointer">
                        <a target="_blank" rel="noreferrer" href="https://www.instagram.com/fyzgayrimenkul/">
                            <InstagramIcon />
                        </a>
                    </li>
                </ul>
            </div>
        </footer>
    )
}

export default Footer;