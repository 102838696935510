import { useEffect, useState } from "react";
import Logo from "./logo";
import LightIcon from "../icons/lightIcon";
import DarkIcon from "../icons/darkIcon";

const Header = () => {

  const [theme, setTheme] = useState(localStorage.getItem('theme') ?? 'dark');
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  useEffect(() => {
    const oldTheme = theme === 'dark' ? 'light' : 'dark';
    document.body.classList.replace(oldTheme, theme);
    localStorage.setItem('theme', theme);
  },[theme])

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    var headerOffset = 73;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
  
    if(showMobileMenu) {
      setShowMobileMenu(false)
    }
    if (element) {
      //element.scrollIntoView({ behavior: "smooth", block: "start" });
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  };

  const handleChangeTheme = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    setTheme(newTheme);
  }

  const handleOpenMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  return (
    <header>
      <nav className="fixed overflow-hidden z-20 w-full bg-white dark:bg-[transparent] dark:shadow-md rounded-b-lg dark:shadow-gray-950 border-b border-[--ui-light-border-color] border-x dark:border-[--ui-dark-border-color] backdrop-blur-2xl">
        <div className="px-6 m-auto max-w-6xl 2xl:px-0">
          <div className="flex flex-wrap items-center justify-between py-2 sm:py-4">
            <div className="w-full items-center flex justify-between lg:w-auto">
              <div
                onClick={() => handleClickScroll("slider")} 
                aria-label="tailus logo"
                className="flex items-center gap-x-3 cursor-pointer"
              >
                <Logo className={"h-10"} />
                <span className="text-gray-600 dark:text-gray-300">
                  <span className="text-[#ff4402] dark:text-[#ff4402]">fyz</span>
                  gayrimenkul
                </span>
              </div>
              <div className="flex gap-2 lg:hidden">
                <button
                  onClick={handleOpenMobileMenu}
                  aria-label="humburger"
                  id="menu"
                  className="relative border bordeer-gray-950/30 dark:border-white/20 size-9 rounded-full transition duration-300 active:scale-95"
                >
                  <div
                    aria-hidden="true"
                    id="line1"
                    className={`${showMobileMenu ? 'absolute rotate-45 left-1/2 -ml-2' : 'rotate-0 m-auto rounded'} transition duration-300 h-[1.5px] w-4 bg-gray-900 dark:bg-gray-300`}
                  ></div>
                  <div
                    aria-hidden="true"
                    id="line2"
                    className={`${showMobileMenu ? 'absolute left-1/2 -ml-2 top-1/2 -rotate-45' : 'rotate-0 m-auto mt-1.5 rounded'} transition duration-300 h-[1.5px] w-4 dark:bg-gray-300 bg-gray-900`}
                  ></div>
                </button>
                <button className="cursor-pointer md:px-4" onClick={handleChangeTheme}>
                    {theme === 'dark' ? <LightIcon /> : <DarkIcon />}
                  </button>
              </div>
            </div>
            <div className="w-full h-0 lg:w-fit flex-wrap justify-end items-center space-y-8 lg:space-y-0 lg:flex lg:h-fit md:flex-nowrap">
              <div className="mt-6 text-gray-600 dark:text-gray-300 md:-ml-4 lg:pr-4 lg:mt-0">
                <ul className="space-y-6 tracking-wide text-base lg:text-sm lg:flex lg:space-y-0">
                  <li
                    onClick={() => handleClickScroll("services")}
                    className="cursor-pointer md:px-4 transition hover:text-primary-600 dark:hover:text-primary-400">
                      <span>Hizmetlerimiz</span>
                  </li>
                  <li
                    onClick={() => handleClickScroll("vision")} 
                    className="cursor-pointer md:px-4 transition hover:text-primary-600 dark:hover:text-primary-400">
                      <span>Vizyon</span>
                  </li>
                  <li 
                    onClick={() => handleClickScroll("mission")} 
                    className="cursor-pointer md:px-4 transition hover:text-primary-600 dark:hover:text-primary-400">
                      <span>Misyon</span>
                  </li>
                  <li 
                    onClick={() => handleClickScroll("contact")} 
                    className="cursor-pointer md:px-4 transition hover:text-primary-600 dark:hover:text-primary-400">
                      <span>İletişim</span>
                  </li>
                  <li className="cursor-pointer md:px-4" onClick={handleChangeTheme}>
                    {theme === 'dark' ? <LightIcon /> : <DarkIcon />}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {showMobileMenu && 
        <div className="animate__animated animate__fadeInDown fixed top-[74px] backdrop-blur-2xl bg-white dark:bg-transparent z-10 w-full text-center py-4 dark:shadow-md dark:shadow-gray-950 border-b border-[--ui-light-border-color] border-x dark:border-[--ui-dark-border-color]">
        <ul className="tracking-wide text-base flex flex-col gap-4 text-gray-600 dark:text-gray-300">
          <li
            onClick={() => handleClickScroll("services")}
            className="cursor-pointer md:px-4 transition hover:text-primary-600 dark:hover:text-primary-400">
              <span>Hizmetlerimiz</span>
          </li>
          <li
            onClick={() => handleClickScroll("vision")} 
            className="cursor-pointer md:px-4 transition hover:text-primary-600 dark:hover:text-primary-400">
              <span>Vizyon</span>
          </li>
          <li 
            onClick={() => handleClickScroll("mission")} 
            className="cursor-pointer md:px-4 transition hover:text-primary-600 dark:hover:text-primary-400">
              <span>Misyon</span>
          </li>
          <li 
            onClick={() => handleClickScroll("contact")} 
            className="cursor-pointer md:px-4 transition hover:text-primary-600 dark:hover:text-primary-400">
              <span>İletişim</span>
          </li>
        </ul>
        </div>
      }
    </header>
  );
};

export default Header;
