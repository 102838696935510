import { useState } from "react";
import LocationIcon from "../icons/locationIcon";
import MailIcon from "../icons/mailIcon";
import PhoneIcon from "../icons/phoneIcon";

const Contact = () => {

  const [name, setName] = useState('');
  const phone = "+90 554 657 5533"
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    if(formIsValid()){

      const newMessage = `Merhaba ben, ${name} \n\n${message}`;

      let url = `https://web.whatsapp.com/send?phone=${phone}`;
          url += `&text=${encodeURI(newMessage)}&app_absent=0`;

      resetForm();
      window.open(url);
    }

    e.preventDefault();
  }

  const resetForm = () => {
    setName('');
    setMessage('');
  }

  const formIsValid = () => {
    return [name, message].map(m => m.trim()).every(e => e)
  }

  return (
    <section id="contact" className="bg-gray-200 dark:bg-[#070c18]">
      <div className="py-16">
        <div className="mx-auto px-6 max-w-6xl text-gray-500">
          <div className="text-center">
            <h2 className="text-3xl text-gray-950 dark:text-white font-semibold">
              İletişim
            </h2>
          </div>
          <div className="grid mt-10 md:grid-cols-2">
            <div className="my-10">
              <h2 className="text-2xl font-semibold dark:text-white">
                Feyyaz ile iletişime geçin
              </h2>
              <p className="max-w-sm mt-5">
                Söyleyecek bir şeyin mi var? Yardım etmek için buradayız. Formu
                doldurun veya e-posta gönderin veya telefonu arayın.
              </p>
              <div className="mt-5">
                <div className="flex items-center mt-2 space-x-2 text-dark-600 dark:text-gray-400">
                  <LocationIcon />
                  <span>
                    Fevzi çakmak mah. şehit sadık sok. No: 19 - Merkez/Düzce
                  </span>
                </div>
                <div className="flex items-center mt-2 space-x-2 text-dark-600 dark:text-gray-400">
                  <MailIcon />
                  <a href="mailto:ozkayafeyyaz@gmail.com">
                    ozkayafeyyaz@gmail.com
                  </a>
                </div>
                <div className="flex items-center mt-2 space-x-2 text-dark-600 dark:text-gray-400">
                  <PhoneIcon />
                  <a href="tel:+90 554 657 55 33">+90 554 657 55 33</a>
                </div>
              </div>
            </div>
            <div>
              <form className="my-10" onSubmit={handleSubmit}>
                <div className="mb-5">
                  <input
                    onChange={e => setName(e.target.value)}
                    placeholder="Ad Soyad"
                    autoComplete="false"
                    className="w-full px-4 py-3 border-2 placeholder:text-gray-800 dark:text-white rounded-md outline-none dark:placeholder:text-gray-200 dark:bg-gray-900 focus:ring-4  border-gray-300 focus:border-gray-600 ring-gray-100 dark:border-gray-600 dark:focus:border-white dark:ring-0"
                    type="text"
                    name="name"
                  />
                </div>
                <div className="mb-3">
                  <textarea
                    onChange={e => setMessage(e.target.value)}
                    name="message"
                    placeholder="Mesaj"
                    className="w-full px-4 py-3 border-2 placeholder:text-gray-800 dark:text-white dark:placeholder:text-gray-200 dark:bg-gray-900   rounded-md outline-none  h-36 focus:ring-4  border-gray-300 focus:border-gray-600 ring-gray-100 dark:border-gray-600 dark:focus:border-white dark:ring-0"
                  ></textarea>
                </div>
                <button
                  disabled={!formIsValid()}
                  type="submit"
                  className="w-full disabled:cursor-not-allowed py-4 font-semibold text-white transition-colors bg-gray-900 rounded-md dark:hover:bg-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-offset-2 focus:ring focus:ring-gray-200 px-7 dark:bg-white dark:text-black "
                >
                  Gönder
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
