const InternationalIcon = () => {
    return (
        <svg fill="currentColor" version="1.1" id="Capa_1" width="70" height="70" viewBox="0 0 93.894 93.893">
            <g>
                <g>
                    <polygon points="53.992,73.872 47.58,73.872 47.58,77.14 57.26,77.14 57.26,67.46 53.992,67.46   "/>
                    <polygon points="53.992,51.663 57.26,51.663 57.26,41.983 47.58,41.983 47.58,45.251 53.992,45.251   "/>
                    <polygon points="10.844,45.251 17.256,45.251 17.256,41.983 7.577,41.983 7.577,51.663 10.844,51.663   "/>
                    <polygon points="17.256,73.872 10.844,73.872 10.844,67.46 7.577,67.46 7.577,77.14 17.256,77.14   "/>
                    <rect x="15.651" y="49.209" width="33.537" height="3.59"/>
                    <rect x="15.651" y="57.704" width="33.537" height="3.589"/>
                    <rect x="15.651" y="66.458" width="33.537" height="3.591"/>
                    <path d="M93.894,39.962c0-17.137-13.94-31.077-31.077-31.077c-15.138,0-27.772,10.882-30.516,25.231H0v50.892h64.838V70.958    C81.033,69.911,93.894,56.417,93.894,39.962z M62.816,12.349c9.313,0,17.559,4.639,22.563,11.723    c-2.524,3.467-9.625,1.533-13.271,2.222c-1.72,0.327-3.777,1.629-4.313,3.397c-0.658,2.168,2.282,2.514,1.103,4.854    c-0.793,1.57-2.742,1.895-4.061,2.986v-3.415H62.15c1.76-0.832,3.156-2.25,3.857-4.068c0.511-1.323,0.723-2.648,1.423-3.913    c0.878-1.585,2.338-2.843,3.338-4.361c0.192-0.294-0.067-0.632-0.374-0.651c-4.576-0.314-6.236,4.826-9.044,7.434    c-1.153,1.072-2.495,1.279-4.007,1.039c-0.988-0.155-0.218-0.59,0.012-1.195c0.719-1.895-2.771-2.745-4.378-3.123    c0.919-1.319,2.907-2.058,4.173-2.886c0.557-0.364,1.834-1.316,1.205-2.14c-1.055-1.378-3.42-1.531-4.99-1.408    c-3.188,0.25-6.062,2.229-8.778,0.405C49.455,14.958,55.835,12.349,62.816,12.349z M50.373,32.979    c1.056-0.121,2.289,0.618,3.293,1.137h-4.242C49.406,33.534,49.63,33.067,50.373,32.979z M60.087,80.257H4.751v-41.39h55.336    V80.257z M69.344,66.788c-0.48-0.885-0.663-1.809,0.283-2.379c1.003-0.604,2.168-0.842,3.156-1.498    c1.582-1.051,2.578-3.674,1.25-5.295c-1.607-1.963-4.727-2.115-7.06-2.267c-0.746-0.047-1.45-0.075-2.136-0.106V43.014    c0.018,0.017,0.031,0.037,0.049,0.054c2.741,2.634,7.327,2.614,10.885,3.38c-1.078,2.994-1.041,6.281,2.637,7.15    c2.951,0.697,6.377-0.25,9.224-1.537C84.066,59.342,77.39,64.831,69.344,66.788z"/>
                </g>
            </g>
        </svg>
    )
}

export default InternationalIcon;