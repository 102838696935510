const LayerIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="16.5 15.4 169.9 171.1" width={60} height={60} fill='currentColor' role="presentation" aria-hidden="true">
            <g>
                <path d="M179 54l-61.9-35c-8.4-4.8-22.9-4.8-31.4 0L23.8 54c-4.7 2.7-7.3 6.4-7.3 10.6 0 4.1 2.6 7.9 7.3 10.5l61.9 35c4.2 2.4 9.8 3.7 15.7 3.7 5.9 0 11.5-1.3 15.7-3.7l61.9-35c4.7-2.7 7.3-6.4 7.3-10.5s-2.6-7.9-7.3-10.6zm-2.8 16.2l-61.9 35c-6.6 3.7-19.1 3.8-25.7 0l-61.9-35c-2.8-1.6-4.4-3.6-4.4-5.6 0-1.9 1.6-4 4.4-5.6l61.9-35c3.3-1.9 8-3 12.9-3 4.8 0 9.5 1.1 12.9 3l61.9 35c2.8 1.6 4.4 3.6 4.4 5.6-.1 1.9-1.7 4-4.5 5.6z"></path>
                <path d="M179 90.7c-1.4-.8-3.1-.3-3.9 1.1-.8 1.4-.3 3.1 1.1 3.9 2.8 1.6 4.4 3.6 4.4 5.6s-1.6 4-4.4 5.6l-61.9 35c-7 3.9-18.7 3.9-25.7 0l-61.9-35c-2.8-1.6-4.4-3.6-4.4-5.6s1.6-4 4.4-5.6c1.4-.8 1.9-2.5 1.1-3.9-.8-1.4-2.5-1.9-3.9-1.1-4.7 2.7-7.3 6.4-7.3 10.6s2.6 7.9 7.3 10.6l61.9 35c4.4 2.5 10 3.7 15.7 3.7 5.6 0 11.3-1.2 15.7-3.7l61.9-35c4.7-2.7 7.3-6.4 7.3-10.6s-2.7-7.9-7.4-10.6z"></path>
                <path d="M179 126.7c-1.4-.8-3.1-.3-3.9 1.1-.8 1.4-.3 3.1 1.1 3.9 2.8 1.6 4.4 3.6 4.4 5.6 0 1.9-1.6 4-4.4 5.6l-61.9 35c-7 3.9-18.7 3.9-25.7 0l-61.9-35c-2.8-1.6-4.4-3.6-4.4-5.6 0-1.9 1.6-4 4.4-5.6 1.4-.8 1.9-2.5 1.1-3.9-.8-1.4-2.5-1.9-3.9-1.1-4.7 2.7-7.3 6.4-7.3 10.5s2.6 7.9 7.3 10.6l61.9 35c4.4 2.5 10 3.7 15.7 3.7 5.6 0 11.3-1.2 15.7-3.7l61.9-35c4.7-2.7 7.3-6.4 7.3-10.6s-2.7-7.8-7.4-10.5z"></path>
            </g>
        </svg>
    )
}

export default LayerIcon;